import { Local } from '@/utils/storage';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  // 主模板
  {
    path: '/',
    name: 'home',
    // redirect: '/siteList',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '/userList',
        name: 'userList',
        component: () => import('../views/tableList/userList.vue'),
      },
      {
        path: '/multiLanguage',
        name: 'multiLanguage',
        component: () => import('@/views/official/multiLanguage.vue'),
      },
      {
        path: '/accountList',
        name: 'accountList',
        component: () => import('@/views/tableList/accountList.vue'),
      },
      {
        path: '/companyList',
        name: 'companyList',
        component: () => import('@/views/company/companyList.vue'),
      },
      {
        path: '/cloudList',
        name: 'cloudList',
        component: () => import('@/views/tableList/cloudList.vue'),
      },
      {
        path: '/appConfig',
        name: 'appConfig',
        component: () => import('@/views/manage/appConfig.vue'),
      },
      {
        path: '/appInstall',
        name: 'appInstall',
        component: () => import('@/views/manage/appInstall.vue'),
      },
      {
        path: '/siteList',
        name: 'siteList',
        component: () => import('@/views/tableList/siteList.vue'),
      },
      {
        path: '/appList',
        name: 'appList',
        component: () => import('@/views/appList/appList.vue'),
      },
      {
        path: '/realinkCount',
        name: 'realinkCount',
        component: () => import('@/views/count/realinkCount.vue'),
      },
      {
        path: '/zcpCount',
        name: 'zcpCount',
        component: () => import('@/views/count/zcpCount.vue'),
      },
      {
        path: '/detail/:actionName/:id?',
        name: 'detail',
        component: () => import('@/views/components/manageDetail.vue'),
      },
      {
        path: '/functionConfig',
        name: 'functionConfig',
        component: () => import('@/views/rightsConfig/functionConfigTable.vue'),
      },
      {
        path: '/rightsConfig',
        name: 'rightsConfig',
        component: () => import('@/views/rightsConfig/packageConfigTable.vue'),
      },
      {
        path: '/resourceLibrary',
        name: 'resourceLibrary',
        component: () => import('@/views/content/resourceLibraryTable.vue'),
      },
      {
        path: '/resourceTags',
        name: 'resourceTags',
        component: () => import('@/views/content/resourceTagsTable.vue'),
      },
      {
        path: '/resourceClass',
        name: 'resourceClass',
        component: () => import('@/views/content/resourceClassTable.vue'),
      },
      {
        path: '/channelConfig',
        name: 'channelConfig',
        component: () => import('@/views/permissions/MenuManagement/ChannelView.vue'),
      },
      {
        path: '/roleConfig',
        name: 'roleConfig',
        component: () => import('@/views/permissions/RoleManagement/RoleView.vue'),
      },
      {
        path: '/userConfig',
        name: 'userConfig',
        component: () => import('@/views/permissions/UserManagement/UserView.vue'),
      },
      {
        path: '/operationRecord',
        name: 'operationRecord',
        component: () => import('@/views/OperationView.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    // path: '/company-select/:id',
    path: '/company-select',
    name: 'company-select',
    component: () => import('@/views/CreateCompany.vue'),
    meta: {
      desc: '选择公司',
    },
  },
];
const path = process.env.NODE_ENV;
const isPoisson = window.location.host.includes('poisson');
const basePath = isPoisson ? '/admin' : (path === 'local' || path === 'production' ? '' : '/admin');

const router = createRouter({
  routes,
  history: createWebHistory(basePath),
});

// 登录态判断
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    const isLogin = Local.get('token');
    if (isLogin) {
      next();
    } else {
      next('/login');
    }
  }
});

export default router;
